.button{
  border-radius: 12px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  display: inline-block;
  font-family: var(--font-secondary);
  font-size: 1.4375rem;
  padding: 12px 18px;
  position: relative;
  text-decoration: none;
  z-index: 100;
}

.button--filled{
  color: var(--color-white);
  background-color: var(--color-red);
  border-color: var(--color-red);
}

.button--filled-blue{
  color: var(--color-white);
  background-color: var(--color-blue);
  border-color: var(--color-blue);
}

.button--filled-black{
  color: var(--color-white);
  background-color: var(--color-blackish);
  border-color: var(--color-blackish);
}

.button--outline-red{
  border-color: var(--color-red);
  color: var(--color-red);
}

.button--highlighted{
  border-color: var(--color-black);
  font-family: var(--font-primary);
  font-size: 1rem;
  font-weight: 800;
}

.button--highlighted-filled{
  background-color: var(--main-color, var(--color-white));
}

.button--block{
  text-align: center;
  width: 100%;
}
