:root{
  --color-black: #000;
  --color-blackish: #373F41;
  --color-white: #FFF;
  --color-grey: #EBEDF0;
  --color-light-grey: #F5F5F5;
  --color-dark-grey: #5C6264;
  --color-blue: #A9E6FF;
  --color-red: #FF7262;
  --color-yellow: #FFEC7C;
  --font-primary: 'KL', Arial, Helvetica, sans-serif;
  --font-secondary: 'ASC', Arial, Helvetica, sans-serif;
  --shadow: 10px 10px 50px 3px rgba(39, 92, 141, 0.1);
}
