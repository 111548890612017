.main-header{
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 18px 5vw;
  position: fixed;
  top: 0;
  transition: background-color .3s;
  width: 100%;
  z-index: 101;
}

.main-header--inner{
  background-color: var(--color-white);
}

.main-header--landing{
  background-color: var(--color-blue);
  position: static;
}

.main-header--short:not(.main-header--inner){
  background-color: var(--color-blue);
}

.main-logo__name{
  transition: fill .5s;

  .main-header--inner &{
    fill: var(--color-blackish);
  }
}

.main-header--with-menu{
  @media (max-width: 959px) {
    background-color: var(--color-white);

    .main-logo__name{
      fill: var(--color-blackish);
    }

    .main-menu{
      top: 100px;
    }
  }
}

.main-logo__link{
  display: block;
  transition: width .5s;
  width: 215px;

  .main-header--short &{
    width: 120px;
  }

  @media (max-width: 959px) {
    width: 120px;
  }
}

.main-menu{
  align-items: center;
  display: flex;

  @media (max-width: 959px) and (orientation: landscape) {
    display: block;
    overflow: auto;
  }

  @media (max-width: 959px) {
    background-color: var(--color-white);
    flex-direction: column;
    height: calc(100vh - 100px);
    justify-content: center;
    left: 0;
    position: fixed;
    text-align: center;
    top: -100vh;
    transition: top .7s;
    width: 100%;
    z-index: 101;
  }
}

.main-menu-trigger{
  @media (min-width: 960px) {
    display: none;
  }
}

.menu-items{
  display: flex;

  @media (max-width: 959px) {
    flex-direction: column;
  }
}

.menu-item{
  font-family: var(--font-secondary);
  font-size: 1.667rem;
  position: relative;
  text-transform: uppercase;

  @media (max-width: 959px) {
    margin-bottom: 30px;
  }

  @media (min-width: 960px) {
    font-size: 1.333rem;
    margin-bottom: 0;
  }
}

.menu-item__link{
  color: var(--color-blackish);
  font-weight: 400;
  padding: 12px;
  text-decoration: none;

  &:hover,
  &:focus{
    text-decoration: underline;
  }
}

.menu-item__link--active{
  font-weight: 700;
  text-decoration: underline;
}

.menu-item__badge{
  font-family: var(--font-primary);
  font-size: 0.667rem;
  font-weight: 800;
  left: 0;
  position: absolute;
  text-transform: none;
  transform: rotate(-5deg);
  top: -50%;
}

.service-items{
  > * {
    &:not(:first-of-type){
      margin-left: 18px;
    }
  }

  @media (max-width: 959px) {
    margin-top: 30px;
  }

  @media (max-width: 959px) and (orientation: landscape) {
    margin-bottom: 30px;
  }
}

$hamburger-layer-width: 21px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 3px;
$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-types: (squeeze);
@import "~hamburgers/_sass/hamburgers/hamburgers";
