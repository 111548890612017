html{
  font-size: 150%;
}

body{
  color: var(--color-dark-grey);
  font-family: var(--font-primary);
  font-weight: 400;
  line-height: 1.2;
  -webkit-text-size-adjust: none;
}

h1, h2, h3, h4, h5, h6{
  color: var(--color-blackish);
  font-family: var(--font-secondary);
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: -0.03em;
}

a{
  color: inherit;
}

.text-red{
  color: var(--color-red);
}

.section--blue{
  background-color: var(--color-blue);
}

.section--yellow{
  background-color: var(--color-yellow);
}

.section--red{
  background-color: var(--color-red);
}

.is-hidden-link{
  text-decoration: none;
}

.main-content--inner{
  padding-top: 102px;

  @media (min-width: 960px) {
    padding-top: 150px;
  }
}

.section{
  p{
    font-size: 0.667rem;
  }
}

.section__content{
  padding-left: 5vw;
  padding-right: 5vw;
}

.section__content--flexed{
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.section__content--flexed-inverted{
  flex-direction: row-reverse;
}

.section-label{
  color: var(--color-blackish);
  font-family: var(--font-secondary);
  text-transform: uppercase;
}

.section__label--clear{
  color: var(--color-white);
}

.section__title{
  font-size: 2rem;
  margin-bottom: 30px;
}
