.main-footer{
  padding-left: 5vw;
  padding-right: 5vw;
}

.main-footer__content{
  font-family: var(--font-secondary);
  font-weight: 700;
  font-size: 1.125rem;
  justify-content: space-between;
  padding-bottom: 30px;
  padding-top: 15px;

  a{
    color: inherit;
    text-decoration: none;
  }

  @media (min-width: 960px) {
    align-items: center;
    display: flex;
  }
}
