.contacts{
  padding: 60px 5%;

  @media (min-width: 960px) {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 120px 15%;
  }
}

.contacts--need-different{
  background-color: var(--color-grey);
  margin-top: 120px;
}

.contacts__content{
  @media (min-width: 960px) {
    width: 50%;
  }
}

.contacts__actions{
  display: flex;
  gap: 30px;
  margin-top: 18px;

  @media (max-width: 959px) {
    flex-direction: column;
  }
}

.contacts__action{
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.meter{
  @media (max-width: 959px) {
    width: 280px;
  }

  @media (min-width: 960px) {
    width: 25%;
  }
}
