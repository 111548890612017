/* html5doctor.com Reset v1.6.1 - http://cssreset.com */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

:root {
  --color-black: #000;
  --color-blackish: #373F41;
  --color-white: #FFF;
  --color-grey: #EBEDF0;
  --color-light-grey: #F5F5F5;
  --color-dark-grey: #5C6264;
  --color-blue: #A9E6FF;
  --color-red: #FF7262;
  --color-yellow: #FFEC7C;
  --font-primary: "KL", Arial, Helvetica, sans-serif;
  --font-secondary: "ASC", Arial, Helvetica, sans-serif;
  --shadow: 10px 10px 50px 3px rgba(39, 92, 141, 0.1);
}

html {
  font-size: 150%;
}

body {
  color: var(--color-dark-grey);
  font-family: var(--font-primary);
  font-weight: 400;
  line-height: 1.2;
  -webkit-text-size-adjust: none;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--color-blackish);
  font-family: var(--font-secondary);
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: -0.03em;
}

a {
  color: inherit;
}

.text-red {
  color: var(--color-red);
}

.section--blue {
  background-color: var(--color-blue);
}

.section--yellow {
  background-color: var(--color-yellow);
}

.section--red {
  background-color: var(--color-red);
}

.is-hidden-link {
  text-decoration: none;
}

.main-content--inner {
  padding-top: 102px;
}
@media (min-width: 960px) {
  .main-content--inner {
    padding-top: 150px;
  }
}

.section p {
  font-size: 0.667rem;
}

.section__content {
  padding-left: 5vw;
  padding-right: 5vw;
}

.section__content--flexed {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.section__content--flexed-inverted {
  flex-direction: row-reverse;
}

.section-label {
  color: var(--color-blackish);
  font-family: var(--font-secondary);
  text-transform: uppercase;
}

.section__label--clear {
  color: var(--color-white);
}

.section__title {
  font-size: 2rem;
  margin-bottom: 30px;
}

.main-header {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 18px 5vw;
  position: fixed;
  top: 0;
  transition: background-color 0.3s;
  width: 100%;
  z-index: 101;
}

.main-header--inner {
  background-color: var(--color-white);
}

.main-header--landing {
  background-color: var(--color-blue);
  position: static;
}

.main-header--short:not(.main-header--inner) {
  background-color: var(--color-blue);
}

.main-logo__name {
  transition: fill 0.5s;
}
.main-header--inner .main-logo__name {
  fill: var(--color-blackish);
}

@media (max-width: 959px) {
  .main-header--with-menu {
    background-color: var(--color-white);
  }
  .main-header--with-menu .main-logo__name {
    fill: var(--color-blackish);
  }
  .main-header--with-menu .main-menu {
    top: 100px;
  }
}

.main-logo__link {
  display: block;
  transition: width 0.5s;
  width: 215px;
}
.main-header--short .main-logo__link {
  width: 120px;
}
@media (max-width: 959px) {
  .main-logo__link {
    width: 120px;
  }
}

.main-menu {
  align-items: center;
  display: flex;
}
@media (max-width: 959px) and (orientation: landscape) {
  .main-menu {
    display: block;
    overflow: auto;
  }
}
@media (max-width: 959px) {
  .main-menu {
    background-color: var(--color-white);
    flex-direction: column;
    height: calc(100vh - 100px);
    justify-content: center;
    left: 0;
    position: fixed;
    text-align: center;
    top: -100vh;
    transition: top 0.7s;
    width: 100%;
    z-index: 101;
  }
}

@media (min-width: 960px) {
  .main-menu-trigger {
    display: none;
  }
}

.menu-items {
  display: flex;
}
@media (max-width: 959px) {
  .menu-items {
    flex-direction: column;
  }
}

.menu-item {
  font-family: var(--font-secondary);
  font-size: 1.667rem;
  position: relative;
  text-transform: uppercase;
}
@media (max-width: 959px) {
  .menu-item {
    margin-bottom: 30px;
  }
}
@media (min-width: 960px) {
  .menu-item {
    font-size: 1.333rem;
    margin-bottom: 0;
  }
}

.menu-item__link {
  color: var(--color-blackish);
  font-weight: 400;
  padding: 12px;
  text-decoration: none;
}
.menu-item__link:hover, .menu-item__link:focus {
  text-decoration: underline;
}

.menu-item__link--active {
  font-weight: 700;
  text-decoration: underline;
}

.menu-item__badge {
  font-family: var(--font-primary);
  font-size: 0.667rem;
  font-weight: 800;
  left: 0;
  position: absolute;
  text-transform: none;
  transform: rotate(-5deg);
  top: -50%;
}

.service-items > *:not(:first-of-type) {
  margin-left: 18px;
}
@media (max-width: 959px) {
  .service-items {
    margin-top: 30px;
  }
}
@media (max-width: 959px) and (orientation: landscape) {
  .service-items {
    margin-bottom: 30px;
  }
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #000;
}

.hamburger-box {
  width: 21px;
  height: 12px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 21px;
  height: 2px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -5px;
}
.hamburger-inner::after {
  bottom: -5px;
}

/*
 * Squeeze
 */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.main-footer {
  padding-left: 5vw;
  padding-right: 5vw;
}

.main-footer__content {
  font-family: var(--font-secondary);
  font-weight: 700;
  font-size: 1.125rem;
  justify-content: space-between;
  padding-bottom: 30px;
  padding-top: 15px;
}
.main-footer__content a {
  color: inherit;
  text-decoration: none;
}
@media (min-width: 960px) {
  .main-footer__content {
    align-items: center;
    display: flex;
  }
}

.contacts {
  padding: 60px 5%;
}
@media (min-width: 960px) {
  .contacts {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 120px 15%;
  }
}

.contacts--need-different {
  background-color: var(--color-grey);
  margin-top: 120px;
}

@media (min-width: 960px) {
  .contacts__content {
    width: 50%;
  }
}

.contacts__actions {
  display: flex;
  gap: 30px;
  margin-top: 18px;
}
@media (max-width: 959px) {
  .contacts__actions {
    flex-direction: column;
  }
}

.contacts__action {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

@media (max-width: 959px) {
  .meter {
    width: 280px;
  }
}
@media (min-width: 960px) {
  .meter {
    width: 25%;
  }
}

.newsletter {
  text-align: center;
  padding-bottom: 90px;
  padding-top: 30px;
}

.newsletter__media {
  margin: 0 auto;
  height: 210px;
  width: 280px;
}

.newsletter__section {
  margin-bottom: 15px;
}

.newsletter__title {
  font-size: 1.4375rem;
  margin-bottom: 12px;
}

.newsletter__socials {
  align-items: center;
  display: flex;
  gap: 12px;
  justify-content: center;
}

.newsletter__social {
  list-style: none;
}

.button {
  border-radius: 12px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  display: inline-block;
  font-family: var(--font-secondary);
  font-size: 1.4375rem;
  padding: 12px 18px;
  position: relative;
  text-decoration: none;
  z-index: 100;
}

.button--filled {
  color: var(--color-white);
  background-color: var(--color-red);
  border-color: var(--color-red);
}

.button--filled-blue {
  color: var(--color-white);
  background-color: var(--color-blue);
  border-color: var(--color-blue);
}

.button--filled-black {
  color: var(--color-white);
  background-color: var(--color-blackish);
  border-color: var(--color-blackish);
}

.button--outline-red {
  border-color: var(--color-red);
  color: var(--color-red);
}

.button--highlighted {
  border-color: var(--color-black);
  font-family: var(--font-primary);
  font-size: 1rem;
  font-weight: 800;
}

.button--highlighted-filled {
  background-color: var(--main-color, var(--color-white));
}

.button--block {
  text-align: center;
  width: 100%;
}