.newsletter{
  text-align: center;
  padding-bottom: 90px;
  padding-top: 30px;
}

.newsletter__media{
  margin: 0 auto;
  height: 210px;
  width: 280px;
}

.newsletter__section{
  margin-bottom: 15px;
}

.newsletter__title{
  font-size: 1.4375rem;
  margin-bottom: 12px;
}

.newsletter__socials{
  align-items: center;
  display: flex;
  gap: 12px;
  justify-content: center;
}

.newsletter__social{
  list-style: none;
}